<template>
  <div>
    <ModalInformeTaller />
    <b-card>
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Talleres a cargo</span>
          </template>
          <b-table
            id="my-table"
            ref="refTablaProfesoresList"
            class="position-relative"
            show-empty
            head-variant="dark"
            hover
            :no-provider-filtering="true"
            responsive
            :busy.sync="isBusy"
            :items="myProvider"
            :fields="fields"
            :current-page="currentPage"
            :empty-text="'No hay registros para mostrar'"
            :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            caption-top
          >
            <template #table-caption>
              <b-row>
                <b-col
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                >
                  <div class="mr-1">
                    <label>Mostrar</label>
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      :clearable="false"
                      size="md"
                      style="width:auto"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>registros</label>
                  </div>
                </b-col>
                <b-col
                  xl="8"
                  lg="8"
                  md="8"
                  sm="12"
                  class="d-flex justify-content-end"
                >

                  <div class="mr-1">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      size="md"
                    />
                  </div>
                  <!-- <div>
                    <b-button
                      variant="primary"
                    >
                      Nuevo profesor DIBU
                    </b-button>
                  </div> -->
                </b-col>

              </b-row>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> cargando...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ from + row.index }}
            </template>

            <template #cell(apellidos_nombres)="row">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    :src="row.item.foto"
                    size="32"
                    :variant="`light-primary`"
                    :text="avatarText(recorteName(row.item.apellidos))"
                  />
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ row.item.apellidos }} {{ row.item.nombres }}
                </b-link>
              </b-media>
            </template>

            <template #cell(estado)="row">

              <b-badge :variant="row.item.estado === 1 ? 'light-success' : 'light-danger'">
                {{ row.item.estado === 1 ? 'activo' : 'inactivo' }}
              </b-badge>

            </template>
            <!-- column: acciones -->
            <template #cell(acciones)="row">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="align-middle text-primary"
                  />
                </template>

                <b-dropdown-item
                  :to="{ name: ruta1(row.item.servicio.codigo), params: { globalId: row.item.taller_global_id, id: row.item.id} }"
                >
                  <feather-icon icon="UsersIcon" />
                  <span class="align-middle ml-50">Estudiantes</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: ruta2(row.item.servicio.codigo), params: { globalId: row.item.taller_global_id, id: row.item.id} }"
                >
                  <feather-icon icon="ListIcon" />
                  <span class="align-middle ml-50">
                    {{ codigo === 'OCADE-ALT-COMP' ? 'Entranamientos' : 'Actividades' }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="generarInformeTaller(row.item.servicio.codigo, row.item.id)"
                >
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Generar Informe</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BTabs, BTab, BTable, BRow, BCol, BFormSelect, BFormInput, BSpinner,
  BDropdown, BDropdownItem, BBadge, BCard, BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ModalInformeTaller from '@/views/ocade/talleres-globales/talleres/ModalInformeTaller.vue'
import profesorTalleresStoreModule from './profesorTalleresStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BPagination,
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    // BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCard,
    ModalInformeTaller,

  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      cursoTallerUpdate: {},
      tallerGlobal_nombre: '',
      sortDirection: 'desc',
      actionTaller: false,
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5, 10, 50, 100],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],
      btnActivo: 0,

      nameRuta: '',
      nameRuta2: '',
    }
  },

  mounted() {
  },
  methods: {
    ruta1(servicio) {
      let ruta = ''
      switch (servicio) {
        case 'OCADE-DEPORTIVO':
          ruta = 'ocade-servicio-deportivo-taller-estudiantes'
          break
        case 'OCADE-CULTURAL':
          ruta = 'ocade-servicio-cultural-taller-estudiantes'
          break
        case 'OCADE-ALT-COMP':
          ruta = 'ocade-prodac-programa-estudiantes'
          break
        default:
          break
      }
      return ruta
    },
    ruta2(servicio) {
      let ruta = ''
      switch (servicio) {
        case 'OCADE-DEPORTIVO':
          ruta = 'ocade-servicio-deportivo-taller-actividades'
          break
        case 'OCADE-CULTURAL':
          ruta = 'ocade-servicio-cultural-taller-actividades'
          break
        case 'OCADE-ALT-COMP':
          ruta = 'ocade-prodac-programa-actividades'
          break
        default:
          break
      }
      return ruta
    },
    async myProvider(ctx) {
      const promise = store.dispatch('profesor-talleres-store-module/getTalleresProfesor', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    generarInformeTaller(codigo, id) {
      this.$emit('abrirModalGenerarInformeTaller', { codigo, id })
    },
  },
  setup() {
    const PROFESOR_TALLERES_APP_STORE_MODULE_NAME = 'profesor-talleres-store-module'
    // Register module
    if (!store.hasModule(PROFESOR_TALLERES_APP_STORE_MODULE_NAME)) store.registerModule(PROFESOR_TALLERES_APP_STORE_MODULE_NAME, profesorTalleresStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFESOR_TALLERES_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFESOR_TALLERES_APP_STORE_MODULE_NAME)
    })
    const resolveTallerStatusVariant = estado => {
      if (estado === 1) return 'success'
      if (estado === 0) return 'danger'
      return 'primary'
    }
    const fields = [
      { key: 'index', label: 'N°', sortable: true },
      { key: 'nombre', label: 'Nombre', sortable: true },
      { key: 'semestre.nombre', label: 'Semestre', sortable: true },
      { key: 'profesor.name', label: 'Profesor', sortable: true },
      {
        key: 'f_inicio', label: 'FECHA Inicio', sortDirection: 'desc', sortable: true,
      },
      { key: 'f_fin', label: 'FECHA Fin', sortable: true },
      { key: 'estado', label: 'Estado', sortable: true },
      { key: 'acciones', label: 'acciones' },
    ]
    return {
      fields,
      resolveTallerStatusVariant,
    }
  },

}
</script>
